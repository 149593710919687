import { getLastElementOrEmptyString, getNthElementOrEmptyString } from "./shared/Helper/array";
import { getBrandName } from "./shared/options";
import { ViewItem } from "./types/event/ViewItem";
import ProductMiniatureDataProvider from "./shared/DataProvider/ProductMiniatureDataProvider";
import CategoryPageDataProvider from "./shared/DataProvider/CategoryPageDataProvider";
import PageDataProvider from "./shared/DataProvider/PageDataProvider";

class ViewItemListEvent {
    constructor() {
        (window as any).dataLayer = (window as any).dataLayer || [];
    }

    init = () => {
        document.addEventListener("DOMContentLoaded", this.sendDataLayerEvent);
        window.prestashop.on("updateProductList", this.sendDataLayerEvent);
    };

    private mapItem = (product: HTMLElement): ViewItem => {
        const productDataProvider = new ProductMiniatureDataProvider(product);
        const categoryDataProvider = new CategoryPageDataProvider();

        const categories = categoryDataProvider.getCategoriesList();

        return {
            item_id: productDataProvider.getProductId(),
            item_variant: "",
            item_name: productDataProvider.getProductName(),
            price: productDataProvider.getPrice(),
            discount: productDataProvider.getDiscount(),
            item_brand: getBrandName(),
            item_list_id: this.getListId(product),
            item_list_name: this.getListName(product),
            item_category: getNthElementOrEmptyString(categories, 0),
            item_category_2: getNthElementOrEmptyString(categories, 1),
            item_category_3: getNthElementOrEmptyString(categories, 2),
            item_category_4: getNthElementOrEmptyString(categories, 3),
            item_category_5: getNthElementOrEmptyString(categories, 4),
            quantity: 1,
        };
    };

    public getListId = (product: HTMLElement): string => {
        const productDataProvider = new ProductMiniatureDataProvider(product);
        const categoryDataProvider = new CategoryPageDataProvider();

        if (window.prestashop.page.page_name == "category") {
            return `category-${categoryDataProvider.getCurrentCategoryId() || 0}`;
        }

        if (window.prestashop.page.page_name == "index") {
            return productDataProvider.getClosestHeadingText().trim().toLowerCase().replace(/\s/g, "_");
        }

        return window.prestashop.page.page_name;
    };

    public getListName = (product: HTMLElement): string => {
        const productDataProvider = new ProductMiniatureDataProvider(product);
        const categoryDataProvider = new CategoryPageDataProvider();

        if (window.prestashop.page.page_name == "category") {
            const categories = categoryDataProvider.getCategoriesList();
            return getLastElementOrEmptyString(categories);
        }

        if (window.prestashop.page.page_name == "index") {
            return productDataProvider.getClosestHeadingText();
        }

        const pageDataProvider = new PageDataProvider();

        return pageDataProvider.getHeadingText();
    };

    private sendDataLayerEvent = () => {
        setTimeout(() => {
            this.pushProductsToDataLayer();
        }, 10);
    };

    private pushProductsToDataLayer = () => {
        const products: NodeListOf<HTMLElement> = document.querySelectorAll("#wrapper .product-miniature.js-product-miniature");

        if (products.length === 0) {
            return;
        }

        const productsData = Array.from(products).map((product: HTMLElement) => this.mapItem(product));

        const dataLayerEvent = {
            event: "view_item_list",
            ecommerce: {
                items: productsData,
            },
        };

        (window as any).dataLayer.push(dataLayerEvent);
    };
}

const viewItemListEvent = new ViewItemListEvent();

viewItemListEvent.init();
