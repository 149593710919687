export default class PageDataProvider {
    public getHeadingText = (): string => {
        const headingElement: HTMLElement | null = document.querySelector("#content-wrapper h1, #content-wrapper h2");

        if (!headingElement) {
            return "";
        }

        return headingElement.textContent?.trim() || "";
    };
}
