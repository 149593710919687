export default class CategoryPageDataProvider {
    public getCategoriesList = (): string[] => {
        if (window.arkongoogleanalytics_config?.categoryPageDataProvider?.getCategoriesList) {
            return window.arkongoogleanalytics_config.categoryPageDataProvider.getCategoriesList();
        }

        if (window.prestashop.page.page_name != "category") {
            return [];
        }

        return this.getCategoriesFromBreadcrumb();
    };

    public getCategoriesFromBreadcrumb = (): string[] => {
        const breadcrumbs: NodeListOf<HTMLElement> = document.querySelectorAll('.breadcrumb [itemprop="itemListElement"] [itemprop="name"]');

        if (!breadcrumbs.length) {
            return [];
        }

        const categories: string[] = [];

        breadcrumbs.forEach((breadcrumb) => {
            categories.push(breadcrumb.textContent?.trim() || "");
        });

        if (categories.length > 1) {
            categories.shift();
        }

        return categories;
    };

    public getCurrentCategoryId = (): number | null => {
        const bodyClasses = document.body.className.split(" ");

        const categoryIdClass = bodyClasses.find((className) => className.includes("category-id-"));

        if (!categoryIdClass) {
            return null;
        }

        return Number.parseInt(categoryIdClass.replace("category-id-", ""));
    };
}
