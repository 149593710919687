import { string2price } from "../Helper/number";

export default class ProductMiniatureDataProvider {
    constructor(private element: HTMLElement) {}

    public getProductId = (): string => {
        return this.element.getAttribute("data-id-product") || "";
    };

    public getProductAttributeId = (): string => {
        return this.element.getAttribute("data-id-product-attribute") || "";
    };

    public getProductName = (): string => {
        const nameElement: HTMLElement | null = this.element.querySelector('[itemprop="name"]');

        if (!nameElement) {
            return "";
        }

        return nameElement.textContent?.trim() || "";
    };

    public getPrice = (): number => {
        const priceElement: HTMLElement = this.element.querySelector('[itemprop="price"], .price');

        if (!priceElement) {
            return 0;
        }

        const price = string2price(priceElement.textContent?.trim() || "");

        if (!price) {
            return 0;
        }

        return price;
    };

    public getRegularPrice = (): number => {
        const regularPriceElement = this.element.querySelector(".regular-price");

        if (!regularPriceElement) {
            return this.getPrice();
        }

        const regularPrice = string2price(regularPriceElement.textContent?.trim() || "");

        if (!regularPrice) {
            return 0;
        }

        return regularPrice;
    };

    public getDiscount = (): number => {
        const regularPrice = this.getRegularPrice();
        const finalPrice = this.getPrice();

        const discount = regularPrice - finalPrice;

        if (discount < 0) {
            return 0;
        }

        return parseFloat(discount.toFixed(2));
    };

    public getClosestHeadingText = (): string => {
        const headingElement = this.element.closest("h1, h2");

        if (!headingElement) {
            return "";
        }

        return headingElement.textContent?.trim() || "";
    };
}
